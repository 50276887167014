import _get from "lodash.get";
import moment from "moment";

export const insuranceDetailsFields = {
  section1: {
    text: "",
    fields: [
      {
        name: "policyId",
        defaultValueFunction: props => {
          return _get(props, "form.offlineFormData.policyId");
        },
        label: "№ на полицата *",
        placeholder: "21001M30004",
        validators: ["required"],
        errorMessages: ["Полето е задължително"],
        type: "text"
      },
      {
        name: "subProduct",
        defaultValueFunction: props => {
          return _get(props, "form.offlineFormData.subProduct");
        },
        type: "select",
        label: "Вид застраховка *",
        validators: ["required"],
        errorMessages: ["Полето е задължително"],
        options: [
          { label: "Имущество", value: "Имущество" },
          { label: "Каско", value: "Каско" },
          { label: "Best Doctors", value: "Best Doctors" },
          { label: "Пътуване в чужбина", value: "Пътуване в чужбина" },
          { label: "ГО", value: "ГО" },
          { label: "Отговорност", value: "Отговорност" },
          { label: "Трудова Злополука", value: "Трудова Злополука" },
          { label: "Допълнително Здравно", value: "Допълнително Здравно" },
          { label: "Плавателни съдове", value: "Плавателни съдове" },
          { label: "Застраховка на вземания", value: "Застраховка на вземания" },
          { label: "Планинска застраховка", value: "Планинска застраховка" },
          { label: "Медицинска застраховка за чужденци ", value: "Медицинска застраховка за чужденци" },
          { label: "Здраве и ценност", value: "Здраве и ценност" },
          { label: "Каско на въздухоплавателни средства (ВС)", value: "Каско на въздухоплавателни средства (ВС)" },
          { label: "Отговорности свързани с експлоатацията на ВС", value: "Отговорности свързани с експлоатацията на ВС" }
        ]
      },
      {
        name: "insurerId",
        defaultValueFunction: props => {
          return _get(props, "form.offlineFormData.insurerId");
        },
        type: "select",
        label: "Застраховател *",
        validators: ["required"],
        errorMessages: ["Полето е задължително"],
        options: [
          { label: "Алианц", value: "allianz" },
          { label: "Армеец", value: "armeec" },
          { label: "Булстрад", value: "bulstrad" },
          { label: "Булстрад Живот", value: "bulstradlife" },
          { label: "Бул Инс", value: "bulins" },
          { label: "Colonnade", value: "colonnade" },
          { label: "Coface", value: "coface" },
          { label: "Дал Бог", value: "dallbogg" },
          { label: "ДЗИ", value: "dzi" },
          { label: "Евроинс", value: "euroins" },
          { label: "Дженерали", value: "generali" },
          { label: "Левинс", value: "levins" },
          { label: "ОЗК", value: "ozk" },
          { label: "Уника", value: "uniqa" }
        ]
      }
    ]
  },
  section2: {
    text: "Срок на застраховката",
    fields: [
      {
        name: "fromTo",
        defaultValueFunction: props => {
          let from = _get(props, "form.offlineFormData.fromTo.from", null);
          let to = _get(props, "form.offlineFormData.fromTo.to", null);
          if (from && from?.constructor?.name !== "M") {
            from = moment(from);
          } else {
            from = moment(from?.$d);
          }
          if (to && to?.constructor?.name !== "M") {
            to = moment(to);
          } else {
            to = moment(to?.$d);
          }
          return {
            from,
            to
          };
        },
        type: "dateRange",
        label: "",
        options: [
          { label: "Начална дата", name: "from" },
          { label: "Крайна дата", name: "to" }
        ],
        validators: ["checkValidDateRange"],
        errorMessages: ["Невалидни дати, втората дата трябва да е след първата"]
      },
      {
        name: "paidTo",
        defaultValueFunction: props => {
          return _get(props, "form.offlineFormData.paidTo", "broker");
        },
        label: "Заплаща се на:",
        type: "radio",
        options: [
          { label: "Брокер", value: "broker" },
          { label: "Застраховател", value: "insurer" }
        ]
      },
      {
        name: "includedInReports",
        defaultValueFunction: props => {
          return _get(props, "form.offlineFormData.includedInReports", false);
        },
        label: "Полицата е включена в репортите",
        type: "checkbox"
      }
    ]
  }
};

export const extractFieldsFromSections = sectionsObj => {
  let fields = [];
  Object.keys(sectionsObj).forEach(key => {
    fields = [...fields, ..._get(sectionsObj, `[${key}].fields`)];
  });
  return fields;
};
