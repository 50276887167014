import React, { useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import PoliciesTableNew from "../Policies/PoliciesTableNew";
import qs from "qs";
import _get from "lodash.get";
import { getKeyByValue } from "../../../utils/helpers";
import withParams from "../../HOC/withParams";
import InnerTabs from "../../InnerTabs";
import withMultipleModals from "../../HOC/withMultipleModals";

const ALL = props => {
  const insurerIdLookup = {
    allianz: "Алианц",
    armeec: "Армеец",
    bulins: "Бул Инс",
    bulstrad: "Булстрад",
    dallbogg: "ДаллБогг",
    dzi: "ДЗИ",
    euroins: "Евроинс",
    generali: "Дженерали",
    levins: "Лев Инс",
    ozk: "ОЗК",
    uniqa: "Уника",
    metlife: "МетЛайф"
  };

  const variantLookup = {
    process: "Предстоящи",
    overdue: "Просрочени",
    issued: "Платени",
    cancelled: "Прекратени",
    partiallyPaid: "Отказано плащане",
    paidElsewhere: "Платени другаде",
    rejected: "Отказани"
  };

  const policiesConfig = {
    pending: "За обработка",
    cancelled: "Прекратени",
    all: "Всички"
  };

  const [selectedTab, setSelectedTab] = useState(sessionStorage.getItem("selectedTab") || "policies");
  const [installmentsSelectedTab, setInstallmentsSelectedTab] = useState(sessionStorage.getItem("installmentsSelectedTab") || getKeyByValue(variantLookup, "Предстоящи"));
  const [policiesSelectedTab, setPoliciesSelectedTab] = useState(sessionStorage.getItem("policiesSelectedTab") || getKeyByValue(policiesConfig, "Всички"));
  const searchQuery = _get(qs.parse(props?.location?.search, { ignoreQueryPrefix: true }), "query", "");
  const searchQueryUserId = _get(qs.parse(props?.location?.search, { ignoreQueryPrefix: true }), "userId", "");

  const handleInstallmentsChange = (e, selected) => {
    setInstallmentsSelectedTab(selected);
    sessionStorage.setItem("installmentsSelectedTab", selected);
  };
  const handlePoliciesChange = (e, selected) => {
    setPoliciesSelectedTab(selected);
    sessionStorage.setItem("policiesSelectedTab", selected);
  };
  const innerTabsPolicies = (
    <InnerTabs
      selected={policiesSelectedTab}
      handleChange={handlePoliciesChange}
      options={[
        { label: "Всички", value: "all" },
        { label: "За обработка", value: "pending" },
        { label: "Прекратени", value: "cancelled" }
      ]}
    />
  );
  const innerTabsInstallments = (
    <InnerTabs
      selected={installmentsSelectedTab}
      handleChange={handleInstallmentsChange}
      options={[
        { label: "Предстоящи", value: getKeyByValue(variantLookup, "Предстоящи") },
        { label: "Просрочени", value: getKeyByValue(variantLookup, "Просрочени") },
        { label: "Издадени", value: getKeyByValue(variantLookup, "Платени") },
        { label: "Всички", value: "all" }
      ]}
    />
  );

  useEffect(() => {}, [props.dataStatuses]);
  return (
    <PageLayout title="Всички полици и вноски" hasGrayBg {...props}>
      {selectedTab === "policies" && (
        <>
          {policiesSelectedTab === getKeyByValue(policiesConfig, "Всички") && (
            <PoliciesTableNew
              title=""
              variant={selectedTab}
              hideActions={true}
              subVariant={policiesSelectedTab}
              primacy="policyPrimacy"
              productDefaultFilter={[]}
              statusLookup={{
                active: "активна"
              }}
              statusDefaultFilter={["active", "pending", "expired", "cancelled", "renewed", "informed", "renewal"]}
              offerDefaultFilter={[]}
              insurerIdLookup={insurerIdLookup}
              insurerIdDefaultFilter={[]}
              searchQuery={searchQuery || searchQueryUserId}
              fromDays="-15"
              toDays="30"
              listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
              setSelectedTab={setSelectedTab}
              innerTabs={innerTabsPolicies}
            />
          )}

          {policiesSelectedTab === getKeyByValue(policiesConfig, "Прекратени") && (
            <PoliciesTableNew
              title=""
              variant={selectedTab}
              subVariant={policiesSelectedTab}
              primacy="policyPrimacy"
              productDefaultFilter={[]}
              statusLookup={{
                cancelled: "прекратена"
              }}
              statusDefaultFilter={["cancelled"]}
              offerDefaultFilter={[]}
              insurerIdLookup={insurerIdLookup}
              insurerIdDefaultFilter={[]}
              searchQuery={searchQuery || searchQueryUserId}
              fromDays="-15"
              toDays="30"
              listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
              setSelectedTab={setSelectedTab}
              innerTabs={innerTabsPolicies}
            />
          )}
          {policiesSelectedTab === getKeyByValue(policiesConfig, "За обработка") && (
            <PoliciesTableNew
              title=""
              hideActions={true}
              variant={selectedTab}
              subVariant={policiesSelectedTab}
              productLookup={{
                PRP: "Имущество"
              }}
              primacy="policyPrimacy"
              productDefaultFilter={[]}
              statusDefaultFilter={["pending"]}
              offerDefaultFilter={[]}
              insurerIdLookup={insurerIdLookup}
              insurerIdDefaultFilter={[]}
              searchQuery={searchQuery || searchQueryUserId}
              fromDays="-15"
              toDays="30"
              listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
              setSelectedTab={setSelectedTab}
              innerTabs={innerTabsPolicies}
            />
          )}
        </>
      )}

      {selectedTab === "installments" && (
        <>
          {installmentsSelectedTab === getKeyByValue(variantLookup, "Предстоящи") && (
            <PoliciesTableNew
              title=""
              hideActions={true}
              variant={selectedTab}
              subVariant={installmentsSelectedTab}
              primacy="duepaymentPrimacy"
              productDefaultFilter={[]}
              statusLookup={{
                active: "активна",
                pending: "обработва се",
                expired: "изтекла",
                cancelled: "прекратена"
              }}
              statusDefaultFilter={["active", "expired", "cancelled"]}
              offerDefaultFilter={[]}
              insurerIdLookup={insurerIdLookup}
              insurerIdDefaultFilter={[]}
              variantLookup={variantLookup}
              variantDefaultFilter={["process"]}
              searchQuery={searchQuery || searchQueryUserId}
              fromDays="-1"
              toDays="5"
              productId
              listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
              setSelectedTab={setSelectedTab}
              innerTabs={innerTabsInstallments}
            />
          )}

          {installmentsSelectedTab === getKeyByValue(variantLookup, "Просрочени") && (
            <PoliciesTableNew
              title=""
              hideActions={true}
              variant={selectedTab}
              subVariant={installmentsSelectedTab}
              productLookup={{
                PRP: "Имущество"
              }}
              primacy="duepaymentPrimacy"
              productDefaultFilter={[]}
              statusLookup={{
                active: "активна",
                pending: "обработва се",
                expired: "изтекла",
                cancelled: "прекратена"
              }}
              statusDefaultFilter={["active", "expired", "cancelled"]}
              offerDefaultFilter={[]}
              insurerIdLookup={insurerIdLookup}
              insurerIdDefaultFilter={[]}
              variantLookup={variantLookup}
              variantDefaultFilter={["overdue"]}
              searchQuery={searchQuery || searchQueryUserId}
              fromDays="-30"
              toDays="30"
              productId
              listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
              setSelectedTab={setSelectedTab}
              innerTabs={innerTabsInstallments}
            />
          )}

          {installmentsSelectedTab === getKeyByValue(variantLookup, "Платени") && (
            <PoliciesTableNew
              title=""
              hideActions={true}
              variant={selectedTab}
              subVariant={installmentsSelectedTab}
              primacy="duepaymentPrimacy"
              productDefaultFilter={[]}
              statusLookup={{
                active: "активна",
                pending: "обработва се",
                expired: "изтекла",
                cancelled: "прекратена"
              }}
              statusDefaultFilter={["active", "expired", "cancelled"]}
              offerDefaultFilter={[]}
              insurerIdLookup={insurerIdLookup}
              insurerIdDefaultFilter={[]}
              variantLookup={variantLookup}
              variantDefaultFilter={["issued", "confirmed"]}
              searchQuery={searchQuery || searchQueryUserId}
              fromDays="-2"
              toDays="1"
              productId
              listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
              setSelectedTab={setSelectedTab}
              innerTabs={innerTabsInstallments}
            />
          )}
          {installmentsSelectedTab === getKeyByValue(variantLookup, "Прекратени") && (
            <PoliciesTableNew
              title=""
              hideActions={true}
              variant={selectedTab}
              subVariant={installmentsSelectedTab}
              primacy="duepaymentPrimacy"
              productDefaultFilter={[]}
              statusLookup={{
                active: "активна",
                pending: "обработва се",
                expired: "изтекла",
                cancelled: "прекратена"
              }}
              statusDefaultFilter={["active", "expired", "cancelled"]}
              offerDefaultFilter={[]}
              insurerIdLookup={insurerIdLookup}
              insurerIdDefaultFilter={[]}
              variantLookup={variantLookup}
              variantDefaultFilter={["cancelled", "partiallyPaid"]}
              searchQuery={searchQuery || searchQueryUserId}
              fromDays="-15"
              toDays="30"
              productId
              listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
              setSelectedTab={setSelectedTab}
              innerTabs={innerTabsInstallments}
            />
          )}

          {installmentsSelectedTab === "all" && (
            <PoliciesTableNew
              title=""
              hideActions={true}
              variant={selectedTab}
              subVariant={installmentsSelectedTab}
              primacy="duepaymentPrimacy"
              productDefaultFilter={[]}
              statusLookup={{
                active: "активна",
                pending: "обработва се",
                expired: "изтекла",
                cancelled: "прекратена"
              }}
              statusDefaultFilter={["active", "expired", "cancelled"]}
              offerDefaultFilter={[]}
              insurerIdLookup={insurerIdLookup}
              insurerIdDefaultFilter={[]}
              variantLookup={variantLookup}
              variantDefaultFilter={[]}
              searchQuery={searchQuery || searchQueryUserId}
              fromDays="-15"
              toDays="30"
              productId
              listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
              setSelectedTab={setSelectedTab}
              innerTabs={innerTabsInstallments}
            />
          )}
        </>
      )}
    </PageLayout>
  );
};
export default withMultipleModals(withParams(ALL));
