import React from "react";
import _get from "lodash.get";
import moment from "moment";
import { Icon } from "@mui/material";
import { LocalShipping as LocalShippingIcon, Warning as WarningIcon, Email as EmailIcon, Campaign, SaveAs } from "@mui/icons-material";
import FolderIcon from "@mui/icons-material/Folder";
import { primary, danger, success } from "./colors";
import ShieldIcon from "@mui/icons-material/Shield";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import ChairIcon from "@mui/icons-material/Chair";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import DirectionsBike from "@mui/icons-material/DirectionsBike";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ProductIcon from "../components/ProductIcon";

import HighlightOffOutlined from "@mui/icons-material/HighlightOffOutlined";
import ForwardToInboxOutlined from "@mui/icons-material/ForwardToInboxOutlined";
import PersonAddDisabledOutlinedIcon from "@mui/icons-material/PersonAddDisabledOutlined";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import AllInclusive from "@mui/icons-material/AllInclusive";
import FindReplaceOutlinedIcon from "@mui/icons-material/FindReplaceOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Status from "../components/Status";
import BackofficeStatus from "../components/pages/Policies/BackofficeStatus";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import { checkCardIsExpired } from "./functions";

export const productImage = code => {
  switch (code) {
    case "TRA":
      return require("../images/travel.jpg");
    case "PRP":
      return require("../images/home.jpg");
    case "ZLO":
      return require("../images/zlo.jpg");
    case "KID":
      return require("../images/kids.jpg");
    case "MED":
      return require("../images/bd.jpg");
    case "BOL":
      return require("../images/bol.jpg");
    case "LIF":
      return require("../images/life.jpg");
    case "CAR":
      return require("../images/cars.jpg");
    case "MTN":
      return require("../images/ski.jpg");
    case "EXT":
      return require("../images/ext.jpg");
    case "GO":
      return require("../images/go.jpg");
    case "CSC":
      return require("../images/casco.jpg");
    case "ASS":
      return require("../images/assistance.jpg");
    default:
      return null;
  }
};

export const productIcon = (code, secondaryText) => {
  switch (code) {
    case "TRA":
      return <ProductIcon key="TRA" id="TRA" label="Пътуване в чужбина" icon={<AirplanemodeActiveIcon />} color="teal" />;
    case "PRP":
      return <ProductIcon key="PRP" id="PRP" label="Имущество" icon={<ChairIcon />} color="warning" />;
    case "HLT":
      return <ProductIcon key="HLT" id="HLT" label="Здравна застраховка" icon={<MedicalServicesIcon />} color="danger" />;
    case "MTN":
      return <ProductIcon key="MTN" id="MTN" label="Планинска застраховка" icon={<FilterHdrIcon />} color="success" />;
    case "EXT":
      return <ProductIcon key="EXT" id="EXT" label="Екстремни спортове" icon={<DirectionsBike />} color="yellow" />;
    case "GO":
      return <ProductIcon key="GO" id="GO" label={secondaryText} icon={<ShieldIcon />} color="primary" />;
    case "CSC":
      return <ProductIcon key="CSC" id="CSC" label={secondaryText} icon={<DirectionsCarIcon />} color="purple" />;
    case "CSC+GO":
      return <ProductIcon key="CSC" id="CSC" label={secondaryText} icon={<AddModeratorIcon />} color="pink" />;
    case "KID":
      return <ProductIcon key="MED" id="MED" label="Лечение без граници" icon={<LoopOutlinedIcon />} color="danger" />;
    case "MED":
      return <ProductIcon key="MED" id="MED" label="Лечение без граници" icon={<AllInclusive />} color="danger" />;
    case "OFF":
      return <ProductIcon key="OFF" id="OFF" label={secondaryText} icon={<BusinessIcon />} color="gray" />;
    case "BOL":
      return "";
    case "LFE":
      return <ProductIcon key="LFE" id="LFE" label={secondaryText} icon={<MonitorHeartIcon />} color="danger" />;
    case "HWP":
      return <ProductIcon key="HWP" id="HWP" label={secondaryText} icon={<VolunteerActivismIcon />} color="danger" />;
    case "CI":
      return <ProductIcon key="CI" id="CI" label={secondaryText} icon={<Diversity1Icon />} color="danger" />;
    case "LFI":
      return <ProductIcon key="LFI" id="LFI" label={secondaryText} icon={<TrendingUpIcon />} color="danger" />;
    case "HCL":
      return <ProductIcon key="HCL" id="HCL" label={secondaryText} icon={<MedicalInformationIcon />} color="deepOrange" />;
    case "CHCL":
      return <ProductIcon key="CHCL" id="CHCL" label={secondaryText} icon={<Diversity2Icon />} color="deepOrange" />;
    case "HCEF":
      return <ProductIcon key="HCEF" id="HCEF" label={secondaryText} icon={<LocalHospitalIcon />} color="deepOrange" />;
    case "CAR":
      return "";
    default:
      return null;
  }
};
export const insurerImage = code => {
  switch (code) {
    case "allianz":
      return require("../images/insurers/allianz.jpg");
    case "armeec":
      return require("../images/insurers/armeec.jpg");
    case "bulins":
      return require("../images/insurers/bulins.jpg");
    case "bulstrad":
      return require("../images/insurers/bulstrad.jpg");
    case "dallbogg":
      return require("../images/insurers/dallbogg.jpg");
    case "dzi":
      return require("../images/insurers/dzi.jpg");
    case "euroins":
      return require("../images/insurers/euroins.jpg");
    case "generali":
      return require("../images/insurers/generali.jpg");
    case "levins":
      return require("../images/insurers/levins.jpg");
    case "ozk":
      return require("../images/insurers/ozk.jpg");
    case "uniqa":
      return require("../images/insurers/uniqa.jpg");

    default:
      return null;
  }
};
export const installmentsTxt = installments => {
  const vnoska = parseInt(installments) <= 12 ? parseInt(installments) : parseInt(installments) % 10;
  switch (vnoska) {
    case 1:
      return `${installments}-ва`;
    case 2:
      return `${installments}-ра`;
    case 3:
    case 4:
    case 5:
    case 6:
    case 9:
    case 10:
    case 11:
    case 12:
    case 0:
      return `${installments}-та`;
    case 7:
    case 8:
      return `${installments}-ма`;
    default:
      return null;
  }
};

export const round = function (value) {
  return Number(Math.round(value + "e2") + "e-2");
};

export const requestError = err => {
  let errorMessage = "";
  if (_get(err, "response.data")) {
    const data = _get(err, "response.data");
    if (_get(data, "Message")) {
      errorMessage = _get(data, "Message");
    } else if (_get(data, "message")) {
      errorMessage = _get(data, "message");
    } else {
      errorMessage = data;
    }
  } else if (_get(err, "response")) {
    errorMessage = JSON.stringify(err.response);
  } else if (err.request) {
    errorMessage = "The request was made but no response was received";
  } else {
    errorMessage = "Something happened in setting up the request that triggered an Error";
  }
  return errorMessage;
};

export const dotsMenuOptionIcon = dotsMenuOptionId => {
  switch (dotsMenuOptionId) {
    case "refund":
      return <ReplyAllOutlinedIcon style={{ color: primary, marginRight: 10 }} />;
    case "payInstallment":
      return <Icon style={{ color: success, marginRight: 10 }}>payment</Icon>;
    case "cancelPolicy":
      return <HighlightOffOutlined style={{ color: danger, marginRight: 10 }} />;
    case "createGOPolicy":
      return <Icon style={{ color: success, marginRight: 10 }}>add</Icon>;
    case "updateDateOfDuePayment":
      return <Icon style={{ color: primary, marginRight: 10 }}>event</Icon>;
    case "checkGOStatus":
      return <FindReplaceOutlinedIcon style={{ color: primary, marginRight: 10 }} />;
    case "editPolicy":
      return <EditOutlinedIcon style={{ color: primary, marginRight: 10 }} />;
    case "shipment":
    case "shipmentCascoPlusGO":
      return <LocalShippingIcon style={{ color: primary, marginRight: 10 }} />;
    case "riskyUserProfile":
    case "riskUser":
      return <PersonOffOutlinedIcon style={{ color: danger, marginRight: 10 }} />;
    case "incorrectPayers":
      return <PersonAddDisabledOutlinedIcon style={{ color: danger, marginRight: 10 }} />;
    case "sendDocuments":
    case "sendDocumentsCascoPlusGO":
      return <ForwardToInboxOutlined style={{ color: primary, marginRight: 10 }} />;
    case "confirmPayment":
      return <Icon style={{ color: primary, marginRight: 10 }}>check</Icon>;
    case "changePolicyStatus":
    case "changeStatusPolicy":
    case "changeStatus":
      return <LoopOutlinedIcon style={{ color: primary, marginRight: 10 }} />;
    case "createPolicy":
      return <FolderIcon style={{ color: primary, marginRight: 10 }} />;
    case "sendOffer":
      return <PlaylistAddCheckOutlinedIcon style={{ color: success, marginRight: 10 }} />;
    case "sendDocumentsToInsurer":
    case "sendPolicy":
    case "sendForEvrotrustSigning":
      return <ForwardToInboxOutlinedIcon style={{ color: primary, marginRight: 10 }} />;
    case "addAnnex":
      return <AddCircleOutline style={{ color: success, marginRight: 10 }} />;
    default:
      return null;
  }
};
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const capitalizeFirstLetter = string => {
  if (!string) {
    return undefined;
  }
  if (string.length > 1) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string.charAt(0).toUpperCase();
};

export const getCurrentWizardStep = step => {
  if (!step || step === "None") return 1;
  const stepNumber = step.match(/\d+/)[0];
  return !isNaN(stepNumber) ? parseInt(stepNumber) + 1 : 1;
};

export const getStepNumberFromStep = step => {
  if (!step || step === "None") return 0;
  const stepNumber = step.match(/\d+/)[0];
  return !isNaN(stepNumber) ? parseInt(stepNumber) : 0;
};

export const cscServiceName = s => {
  if (s === "EE") return "Експертна оценка";
  if (s === "TS") return "Доверен сервиз";
  if (s === "OS") return "Официален сервиз";
};

export const calculateDueDate = (fromDate, monthsPrecision, installments) => {
  let startDate = fromDate || moment();

  switch (installments) {
    case 1:
      return startDate;
    case 2:
      return startDate.add(monthsPrecision * 6, "M");
    case 4:
      return startDate.add(monthsPrecision * 3, "M");
    case 12:
      return startDate.add(monthsPrecision, "M");
  }
};

export const getPolicyStatus = (policy, variant, subVariant) => {
  const { policyData, status } = policy;
  const isActive = new Date(policyData?.validTo) > new Date();
  const productId = _get(policy, "productId");
  let frontendStatus = status;

  if (["expiring", "active", "all"].includes(subVariant)) {
    if (status === "expiring") {
      frontendStatus = isActive ? (["TRA", "EXT", "MTN"].includes(productId) ? "active" : "expiring") : "expired";
    } else if (status === "expired") {
      // за момента expired се връща само за "TRA", "EXT", "MTN"
      frontendStatus = "expired";
    }
  }

  const backofficeStatus = _get(policy, "backofficeStatus", "");
  return (
    <>
      {(variant === "policies" || (variant === "queries" && subVariant === "issued")) && <Status status={frontendStatus} />}
      {!(
        (backofficeStatus === "CustomerInformedForExpiring" && status === "informed") ||
        (variant === "queries" && subVariant === "issued") ||
        (variant === "policies" && subVariant === "active")
      ) && <BackofficeStatus status={backofficeStatus} variant={variant} productId={productId} />}
    </>
  );
};

export const getPolicyStatusCSCplusGO = policy => {
  return <Status status={policy?.statusCombined} />;
};

export const getPolicyOffersStatusCSCplusGO = policy => {
  return <BackofficeStatus status={policy?.offerStatusCombined} productId="CSC+GO" />;
};

export const getDepositedTotal = (policy, currentProduct) => {
  let totalPromo, total;

  if (currentProduct === "CSC+GO") {
    total = _get(policy, "totalBoth", 0);
    totalPromo = _get(policy, "totalPromoBoth", 0);
  } else {
    total = _get(policy, "total", 0);
    totalPromo = _get(policy, "totalPromo", 0);
  }
  const hasPromoPrice = totalPromo && totalPromo !== total;
  const promoCode = _get(policy, "policyData.promoCode");
  return (
    <>
      <b style={{ color: primary }}>{total.toFixed(2)}</b> <span className="font-small"> лв.</span>
      {!!hasPromoPrice && (
        <>
          <br />
          <b className="font-small">promo: </b>
          <b style={{ color: danger }}>{totalPromo.toFixed(2)}</b>{" "}
          <span className="font-small">
            {" лв."}
            <br />
            {promoCode && promoCode}
          </span>
        </>
      )}
    </>
  );
};

export const getAmountDuePayment = policy => {
  const cardNumber = _get(policy, "paymentDataDuePayments.CARD_NUMBER");
  const reccPmntId = _get(policy, "paymentDataDuePayments.RECC_PMNT_ID");
  const reccPmntExpiry = _get(policy, "paymentDataDuePayments.RECC_PMNT_EXPIRY");
  const isOffline = _get(policy, "isOffline");
  const missingCardData = !isOffline && !(cardNumber && reccPmntId && reccPmntExpiry);
  let cardExpired;
  if (!isOffline && !missingCardData) {
    cardExpired = checkCardIsExpired(reccPmntExpiry);
  }
  const promoPrice = _get(policy, "promoAmountDuepayments");
  const promoCode = _get(policy, "promoCode");
  const amount = policy?.amount;
  const hasPromoPrice = promoPrice && promoPrice !== amount;
  return (
    <React.Fragment>
      <b style={{ color: primary }}>{amount?.toFixed(2)}</b>
      <span className="font-small"> лв.</span>
      {!!hasPromoPrice && (
        <>
          <br />
          <b className="font-small">promo: </b>
          <b style={{ color: danger }}>{promoPrice?.toFixed(2)}</b> <span className="font-small"> лв.</span>
          {promoCode ? (
            <p className="font-small" style={{ margin: 0 }}>
              <b>promocode: </b>({promoCode})
            </p>
          ) : (
            ""
          )}
        </>
      )}
      <br />
      {missingCardData && <p className="font-small red">липсват данни за карта</p>}
      {cardExpired && <p className="font-small red">изтекла карта</p>}
    </React.Fragment>
  );
};

export const getSumTotal = (policy, currentProduct) => {
  let fullAmountTotal, fullAmountPromo;
  if (currentProduct === "CSC+GO") {
    fullAmountTotal = _get(policy, `fullAmountTotalBoth`, 0);
    fullAmountPromo = _get(policy, `fullAmountPromoBoth`, 0);
  } else {
    fullAmountTotal = _get(policy, "fullAmountTotal", 0);
    fullAmountPromo = _get(policy, "fullAmountPromo", 0);
  }
  return (
    <>
      <b style={{ color: primary }}>{fullAmountTotal.toFixed(2)}</b> <span className="font-small"> лв.</span>
      {!!fullAmountPromo && fullAmountTotal !== fullAmountPromo && (
        <>
          <br />
          <b className="font-small">promo: </b>
          <b style={{ color: danger }}>{fullAmountPromo.toFixed(2)}</b> <span className="font-small"> лв.</span>
        </>
      )}
    </>
  );
};

export const stickerOrGreenCardTxt = stickerType => {
  if (stickerType === "goSticker") {
    return "Стикер";
  }
  if (stickerType === "goGreenCard") {
    return "Зелена Карта";
  }
  return " - ";
};

export const clearTabsFromSessionStorage = () => {
  sessionStorage.removeItem("policiesSelectedTab");
  sessionStorage.removeItem("selectedTab");
  sessionStorage.removeItem("installmentsSelectedTab");
  sessionStorage.removeItem("offerSelectedTab");
};

export const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const validatePercentage = percentage => {
  const rgx = /^[0-9]*\.?[0-9]*$/;
  return percentage.match(rgx) && percentage >= 0 && percentage <= 100;
};
