const products = [
  {
    id: "TRA",
    name: "Пътуване в чужбина"
  },
  {
    id: "PRP",
    name: "Имущество"
  },
  {
    id: "OFF",
    name: "Офлайн"
  },
  {
    id: "KID",
    name: "Детска"
  },
  {
    id: "MED",
    name: "Best doctors"
  },
  {
    id: "BOL",
    name: "Болничен престой"
  },
  {
    id: "MTN",
    name: "Планинска"
  },
  {
    id: "EXT",
    name: "Екстремни"
  },
  {
    id: "GO",
    name: "Гражданска"
  },
  {
    id: "CSC",
    name: "Каско"
  },
  {
    id: "CSC+GO",
    name: "Каско + Гражданска"
  },
  {
    id: "HWP",
    name: "Здраве с предимство"
  },
  {
    id: "LFE",
    name: "Застраховка Живот"
  },
  {
    id: "CI",
    name: "Фирмена Застраховка Живот"
  },
  {
    id: "LFI",
    name: "Инвестиционен Живот"
  }
];

const automaticStickers = [
  { id: "levins", active: true },
  { id: "bulins", active: true },
  { id: "generali", active: true },
  { id: "ozk", active: true },
  { id: "dzi", active: true },
  { id: "euroins", active: true },
  { id: "dallbogg", active: true },
  { id: "bulstrad", active: true },
  { id: "allianz", active: true },
  { id: "uniqa", active: true }
];

const automaticAndManualPolicyIssuance = {
  ["CSC+GO"]: [
    { id: "bulstrad", active: true },
    { id: "euroins", active: true },
    { id: "levins", active: true }
  ]
};

const productIdInsurerMap = {
  HWP: "metlife",
  LFE: "metlife",
  LFI: "metlife",
  CI: "metlife",
  HCL: "bulstrad",
  CHCL: "bulstrad",
  HCEF: "bulstrad",
  HLT: "uniqa"
};

export default { products, automaticStickers, automaticAndManualPolicyIssuance, productIdInsurerMap };
