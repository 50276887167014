import { request } from "./APIservice";
import { POST, GET, DELETE } from "../constants/api";
import _get from "lodash.get";
import { rolesMatched } from "../components/PrivateRoute/PrivateRoute";
import { PDF_SERVICE_URL } from "../utils/config";

export const getPoliciesNew = async (query, utmSources, roles, type, from, to, variant, subVariant) => {
  const { filters, orderBy, orderDirection, page, pageSize, search } = query;
  let filtersArr = [];
  let productId;
  if (filters) {
    filters.forEach(f => {
      if (_get(f, "column.field") === "productId") {
        productId = f.value;
      }
      filtersArr.push({ [_get(f, "column.field")]: f.value });
    });
  }

  let order;
  if (variant === "installments" && subVariant === "issued") {
    order = {
      orderByKey: "dueDate",
      orderDirection: "desc"
    };
  }
  let orderByObj;
  if (_get(orderBy, "field") === "period") {
    orderByObj = { validFrom: orderDirection };
  } else {
    orderByObj = _get(orderBy, "field") ? { [_get(orderBy, "field")]: orderDirection } : undefined;
  }

  const getData = {
    filters: filtersArr,
    page,
    search: search.trim(),
    pageSize,
    orderBy: orderByObj,
    from,
    to,
    ...(order || {})
  };
  const dateType = ((v, sv) => {
    if (["queries", "offers"].includes(v)) return "offerDate";
    if (v === "policies" && !["expiring", "renewed", "expired", "informed"].includes(sv)) return "contractDate";
    if (v === "installments") return "dueDate";
    if (["expiring", "renewed", "expired", "informed"].includes(sv)) return "validTo";
    return "";
  })(variant, subVariant);

  if (rolesMatched(["viewer"], roles)) {
    getData.utmSources = utmSources || [];
  }
  if (rolesMatched(["admin", "employee", "viewer"], roles)) {
    return await request(POST, productId.includes("CSC+GO") ? `/policiesByGroup` : `/policies_2`, { ...getData, type, from, to, dateType }, null, true, true);
  }
  if (rolesMatched(["viewer"], roles)) {
    getData.utmSources = utmSources;
    return await request(POST, `/policies-viewer`, getData, null, true, true);
  }
  return {};
};

export const getPolicies = async (query, utmSources, roles, type, from, to, product) => {
  const { filters, orderBy, orderDirection, page, pageSize, search } = query;
  let filtersArr = [];
  if (filters) {
    filters.forEach(f => {
      filtersArr.push({ [_get(f, "column.field")]: f.value });
    });
  }
  const getData = {
    filters: filtersArr,
    page,
    search: search.trim(),
    pageSize,
    orderBy: _get(orderBy, "field") ? { [_get(orderBy, "field")]: orderDirection } : undefined,
    from,
    to
  };
  if (type === "expiring") {
    if (rolesMatched(["admin", "employee"], roles)) {
      return await request(POST, `/policies`, { ...getData, type, from, to, product }, null, true, true);
    }
    return {};
  } else {
    if (rolesMatched(["admin", "employee"], roles)) {
      return await request(POST, `/policies`, getData, null, true, true);
    }
    if (rolesMatched(["viewer"], roles)) {
      getData.utmSources = utmSources;
      return await request(POST, `/policies-viewer`, getData, null, true, true);
    }
  }
  return {};
};

export const uploadPolicy = async data => {
  const { file, policyNumber, date, policy, isFromWizard, sticker, greenCard, prefix } = data;
  const formData = new FormData();
  formData.append("policyOid", policy._id);
  if (file) {
    formData.append("file", file);
  }
  if (isFromWizard) {
    formData.append("isFromWizard", isFromWizard);
  }
  if (policyNumber) {
    formData.append("policyNumber", policyNumber);
  }
  if (date) {
    formData.append("date", date);
  }
  if (sticker) {
    formData.append("sticker", sticker);
  }
  if (greenCard) {
    formData.append("greenCard", greenCard);
  }
  if (prefix) {
    formData.append("prefix", prefix);
  }

  const response = await request(
    POST,
    `/backOffice/uploadPolicy`,
    formData,
    {
      "content-type": null
    },
    true,
    true,
    true
  );
  return response;
};

export const uploadFile = async data => {
  const { file, policy, typeDocument, isHidden, isFromWizard } = data;
  const { paymentId = 0 } = policy;
  const { annexId } = data;
  const formData = new FormData();
  formData.append("policyOid", policy._id);
  formData.append("file", file);
  formData.append("typeDocument", typeDocument);
  formData.append("isHidden", isHidden);
  if (isFromWizard) {
    formData.append("isFromWizard", isFromWizard);
  }

  if (paymentId) formData.append("paymentId", paymentId);
  if (annexId) formData.append("annexId", annexId);

  const response = await request(
    POST,
    `/backOffice/uploadExtraFilePolicy`,
    formData,
    {
      "content-type": null
    },
    true,
    true,
    true
  );
  return response;
};
export const uploadTalon = async data => {
  const response = await request(
    POST,
    `/documents-storage/upload-picture`,
    data,
    {
      "content-type": null
    },
    true,
    true,
    true
  );
  return response;
};

export const sendFilesForSigningEvrotrust = async data => {
  const response = await request(POST, `/backOffice/sendFilesForSigning`, data, {}, true, true, true);
  return response;
};

export const signPDF = async data => {
  const { file, isBulstrad, isTwice } = data;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("isBulstrad", isBulstrad);
  formData.append("isTwice", isBulstrad ? false : isTwice);
  const response = await request(
    POST,
    `/uploadPDFForSigning`,
    formData,
    {
      "content-type": null
    },
    true,
    true
  );
  return response;
};

export const getPayments = async query => {
  const { date, days } = query;
  const getData = {
    date,
    days
  };
  const response = await request(GET, `/installments`, getData, null, true, true);
  return response;
};

export const getPaymentsNew = async query => {
  const { from, to } = query;
  const getData = {
    from,
    to
  };
  const response = await request(GET, `/installments-new`, getData, null, true, true);
  return response;
};

export const getPaymentsList = async query => {
  const { from, to, variant, page, pageSize, productId, orderBy, orderDirection } = query;
  const orderByKey = [_get(orderBy, "field")];
  const getData = {
    from,
    to,
    variant,
    page,
    pageSize,
    productId,
    orderByKey: orderByKey ? orderByKey : undefined,
    orderDirection: orderDirection ? orderDirection : undefined
  };
  const response = await request(GET, `/installmentList`, getData, null, true, true);
  return response;
};
export const sendReportBestDoctors = async data => {
  const { fromDate, toDate, email } = data;
  const response = await request(GET, `/exports/bd-monthly-report/${fromDate}/${toDate}/${email}`, {}, {}, true, true, true);
  return response;
};
export const sendReportKFN = async data => {
  const { fromDate, toDate, email } = data;
  const response = await request(GET, `/exports/kfn-report/${fromDate}/${toDate}/${email}`, {}, {}, true, true, true);
  return response;
};
export const refund = async data => {
  const response = await request(POST, `/refund`, data, {}, true, true);
  return response;
};
export const cancelPolicy = async data => {
  const response = await request(POST, `/cancelPolicy`, data, {}, true, true);
  return response;
};
export const setPolicyBackofficeStatus = async data => {
  const response = await request(POST, `/setPolicyBackofficeStatus`, data, {}, true, true);
  return response;
};
export const addCommentToPolicy = async data => {
  const response = await request(POST, `/addCommentToPolicy`, data, {}, true, true);
  return response;
};
export const addCommentToDuePayment = async data => {
  const response = await request(POST, `/addCommentToDuePayment`, data, {}, true, true);
  return response;
};

export const updateDateOfDuePayment = async data => {
  const response = await request(POST, `/updateDateOfDuePayment`, data, {}, true, true);
  return response;
};

export const createGOPolicy = async data => {
  delete data.dateTo;
  const response = await request(POST, `/backOffice/issuePolicy/go`, data, {}, true, true, true);
  return response;
};
export const checkGOStatus = async data => {
  const response = await request(GET, `/check-go-status`, data, {}, true, true);
  return response;
};

export const getRefunds = async data => {
  const response = await request(GET, `/refunds`, data, {}, true, true);
  return response;
};

export const getStatisticsCasco = async () => {
  const response = await request(
    GET,
    "https://api.applicationinsights.io/v1/apps/724b78bd-92b6-43ad-8dcb-5eaa06db2f84/metrics/requests/count?timespan=P30D&interval=P1D&filter=startswith(request%2Fname%2C%20'GET%20%2Futils%2FpriceRange')%20%20and%20startswith(cloud%2FroleName%2C%20'Web')%20",
    {},
    { "x-api-key": "gzl6jih116l4eo2npueuazcssw2rb52cy1f4acew" },
    null,
    true
  );
  return response;
}; // todo -> no token
export const getStatisticsFines = async () => {
  const response = await request(
    GET,
    "https://api.applicationinsights.io/v1/apps/724b78bd-92b6-43ad-8dcb-5eaa06db2f84/metrics/requests/count?timespan=P30D&interval=P1D&filter=startswith(request%2Fname%2C%20'POST%20%2Fexternal%2FsaveCaptchaCarData')%20%20and%20startswith(cloud%2FroleName%2C%20'Web')%20",
    {},
    { "x-api-key": "gzl6jih116l4eo2npueuazcssw2rb52cy1f4acew" },
    null,
    true
  );
  return response;
}; // todo -> no token
export const getStatisticsGO = async () => {
  const response = await request(
    GET,
    "https://api.applicationinsights.io/v1/apps/724b78bd-92b6-43ad-8dcb-5eaa06db2f84/metrics/requests/count?timespan=P30D&interval=P1D&filter=startswith(request%2Fname%2C%20'GET%20%2Fgo%2Feuroins')%20%20and%20startswith(cloud%2FroleName%2C%20'Web')%20",
    {},
    { "x-api-key": "gzl6jih116l4eo2npueuazcssw2rb52cy1f4acew" },
    null,
    true
  );
  return response;
}; // todo -> no token

export const addSeqNumbers = async data => {
  const response = await request(POST, `/seqNumbers`, data, {}, true, true);
  return response;
};

export const addPromoCode = async data => {
  const response = await request(POST, `/add-promo-code`, data, {}, true, true);
  return response;
};
export const removePromoCode = async data => {
  const response = await request(DELETE, `/remove-promo-code`, data, {}, true, true);
  return response;
};
export const getPromoCodes = async data => {
  const response = await request(GET, `/promo-codes-new`, data, {}, true, true);
  return response;
};

export const addReferral = async data => {
  const response = await request(POST, `/add-referral`, data, {}, true, true);
  return response;
};
export const removeReferral = async data => {
  const response = await request(DELETE, `/remove-referral`, data, {}, true, true);
  return response;
};
export const getReferrals = async data => {
  const response = await request(GET, `/referrals`, data, {}, true, true);
  return response;
};
export const getReferralUsers = async data => {
  const response = await request(GET, `/referrals/get-users`, data, {}, true, true);
  return response;
};

export const calculatePolicyCommission = async data => {
  const response = await request(POST, `/calculate-commission`, data, {}, true, true);
  return response;
};

export const getAdditionalPolicyFiles = async policyOid => {
  const response = await request(GET, `${PDF_SERVICE_URL}/${policyOid}/all-files-system`, {}, {}, true);
  return response;
};

export const payInstallmentRequest = async data => {
  const response = await request(POST, `/backOffice/issueInstallment/go`, data, {}, true, true, true);
  return response;
};
export const createOfflinePolicy = async data => {
  const response = await request(POST, `/policy/create-offline`, data, {}, true, true, true);
  return response;
};

export const sendReportSales = async data => {
  const { fromDate, toDate, email, insurer } = data;
  const response = await request(GET, `/exports/policy-prem-xlsx/${fromDate}/${toDate}/${email}/${insurer}`, {}, {}, true, true, true);
  return response;
};

export const sendReportsForPeriod = async data => {
  const { fromDate, toDate, email, insurer } = data;
  const response = await request(GET, `/exports/reports-for-period/${fromDate}/${toDate}/${email}/${insurer}`, {}, {}, true, true, true);
  return response;
};

export const sendReportsTransactions = async data => {
  const { fromDate, toDate, email } = data;
  const response = await request(GET, `/exports/reports-transactions/${fromDate}/${toDate}/${email}`, {}, {}, true, true, true);
  return response;
};

export const sendDocuments = async data => {
  const response = await request(POST, `/backOffice/send-documents`, data, {}, {}, true, true, true);
  return response;
};

export const sendAnnex = async data => {
  const response = await request(POST, `/backOffice/send-annex`, data, {}, {}, true, true, true);
  return response;
};

export const sendDocumentsToInsurer = async data => {
  const response = await request(POST, `/backOffice/send-documents-metlife`, data, {}, {}, true, true, true);
  return response;
};

export const getDocuments = async data => {
  const { policyDBId } = data;
  const response = await request(GET, `${PDF_SERVICE_URL}/${policyDBId}/all-files`, {}, {}, true, true);
  return response;
};

// NOT USED
export const sendEndingPolicies = async data => {
  const { month, email } = data;
  const response = await request(GET, `/exports/ending-policies/${month}/${email}`, {}, {}, true, true, true);
  return response;
};

// NOT USED
export const sendEndingPayments = async data => {
  const { month, email } = data;
  const response = await request(GET, `/reports/duePaymentsV2/${month}/${email}`, {}, {}, true, true, true);
  return response;
};

export const getIndividualPolicy = async policyId => {
  const response = await request(GET, `/policy/${policyId}`, {}, {}, true, true);
  return response;
};

// MOVE to api/backoffice
export const getIndividualPolicyByRequestId = async (groupRequestId, productId) => {
  const response = await request(GET, `/policy-by-groupRequestId/${groupRequestId}/${productId}`, {}, {}, true, true);
  return response;
};

// MOVE to api/backoffice
export const updatePayments = async data => {
  const response = await request(POST, `/policy/updatePayments`, data, {}, true, true, true);
  return response;
};

// MOVE to api/backoffice
export const updatePolicy = async data => {
  const response = await request(POST, `/policy/updatePolicy`, data, {}, true, true, true);
  return response;
};
export const commissionReports = async (dateFrom, dateTo) => {
  const response = await request(GET, `/backOffice/get-commission-reports/${dateFrom}/${dateTo}`, {}, {}, {}, true, true);
  return response;
};
export const weeklyReports = async (dateFrom, dateTo) => {
  const response = await request(GET, `/backOffice/get-weekly-reports/${dateFrom}/${dateTo}`, {}, {}, {}, true, true);
  return response;
};
export const monthlyReports = async (dateFrom, dateTo) => {
  const response = await request(GET, `/backOffice/get-monthly-reports/${dateFrom}/${dateTo}`, {}, {}, {}, true, true);
  return response;
};
export const reportsPath = async (dateFrom, dateTo, type) => {
  const response = await request(GET, `/backOffice/get-reports/${dateFrom}/${dateTo}/${type}`, {}, {}, {}, true, true);
  return response;
};

export const setConfirmationStatusPayment = async data => {
  const response = await request(POST, `/setConfirmationStatusPayment`, data, {}, true, true);
  return response;
};

export const setStickerStatus = async data => {
  const response = await request(POST, `/setStickerStatus`, data, {}, true, true);
  return response;
};

// MOVE to api/backoffice
export const setPolicyStatus = async data => {
  const response = await request(POST, `/setPolicyStatus`, data, {}, true, true);
  return response;
};

// MOVE to api/backoffice
export const setUserRisk = async data => {
  const response = await request(POST, `/user/risk-status`, data, {}, true, true, true);
  return response;
};

// MOVE to api/backoffice
export const addDuePayments = async data => {
  const response = await request(POST, `/add-duepayments`, data, {}, true, true);
  return response;
};

// MOVE to api/backoffice
export const cloneBDPolicy = async data => {
  const response = await request(POST, `/clone-best-doctors`, data, {}, true, true);
  return response;
};

// MOVE to api/backoffice
export const getPurchases = async data => {
  const response = await request(GET, `/purchases`, data, null, true, true);
  return response;
};

// MOVE to api/backoffice
export const getBlacklist = async () => {
  const response = await request(GET, `/blacklist`, {}, {}, true);
  return response;
};

// MOVE to api/backoffice
export const addUserToBlacklist = async data => {
  const response = await request(POST, `/blacklist`, data, {}, true);
  return response;
};

// MOVE to api/backoffice
export const removeUserFromBlacklist = async data => {
  const response = await request(DELETE, `/blacklist`, data, {}, true);
  return response;
};

// MOVE to api/backoffice
export const createCSCPolicy = async data => {
  const response = await request(POST, `/issuePolicy/casco`, data, {}, true);
  return response;
};

// MOVE to api/backoffice
export const cancelPolicySticker = async (policyDBId, installment, docType, docNo) => {
  const response = await request(GET, `/cancel-go-document/${policyDBId}/${installment}/${docType}/${docNo}`, {}, {}, true);
  return response;
};

// MOVE to api/backoffice
export const getVehicleData = async data => {
  const response = await request(GET, `/carutils/vehicleData`, data, {}, true, true, true);
  return response;
};
export const getFullVehicleData = async data => {
  const response = await request(POST, `/vehicleData`, data, {}, true, true);
  return response;
};

// MOVE to api/backoffice
export const setWizardStatus = async data => {
  const response = await request(POST, `/set-wizard-status`, data, {}, true, true);
  return response;
};
// MOVE to api/backoffice
export const setWizardStatusesByGroupRequestId = async data => {
  const response = await request(POST, `/set-wizard-statuses-by-groupRequestId`, data, {}, true, true);
  return response;
};

// MOVE to api/backoffice
export const moveToIssued = async data => {
  const response = await request(POST, `/policy/moveToIssued`, data, {}, true, true, true);
  return response;
};

// MOVE to api/backoffice
export const getPaymentsByRequestId = async requestId => {
  const response = await request(GET, `/installment-payments/${requestId}`, {}, {}, true, true, true);
  return response;
};
export const getFreeStickerAndGreenCard = async insurer => {
  const response = await request(GET, `/get-free-sticker-and-green-card/${insurer}`, {}, {}, true, true);
  return response;
};
export const reserveStickerAndGreenCardForDuePayment = async data => {
  const response = await request(POST, `/reserve-sticker-and-green-card-for-duePayment`, data, {}, true, true);
  return response;
};
