import React, { createElement, useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import PoliciesTableNew from "../Policies/PoliciesTableNew";
import qs from "qs";
import _get from "lodash.get";
import routesConfig from "../../../routes/routesConfig";
import { capitalizeFirstLetter, getKeyByValue } from "../../../utils/helpers";
import withMultipleModals from "../../HOC/withMultipleModals";
import withParams from "../../HOC/withParams";
import _find from "lodash.find";
import * as AllActionModals from "../../ActionModals";
import InnerTabs from "../../InnerTabs";
import ActionsProvider from "../../ActionsProvider";

const LFE = props => {
  const insurerIdLookup = {
    metlife: "Метлайф",
    bulstrad: "Булстрад"
  };

  const variantLookup = {
    process: "Предстоящи",
    informed: "Информирани",
    overdue: "Просрочени",
    issued: "Платени",
    rejected: "Прекратени"
  };

  const policiesConfig = {
    active: "Активни",
    cancelled: "Прекратени",
    all: "Всички"
  };

  const subVariantsConfig = {
    queries: "offerSelectedTab",
    policies: "policiesSelectedTab",
    installments: "installmentsSelectedTab"
  };

  //todo този код може да отиде в withMultipleModals HOK-a
  const modals = {};
  props.data.forEach(el => {
    modals[el.id] = AllActionModals[capitalizeFirstLetter(el.id)];
  });

  const [selectedTab, setSelectedTab] = useState(sessionStorage.getItem("selectedTab") || "queries");
  const [installmentsSelectedTab, setInstallmentsSelectedTab] = useState(sessionStorage.getItem("installmentsSelectedTab") || getKeyByValue(variantLookup, "Предстоящи"));
  const [offerSelectedTab, setOfferSelectedTab] = useState(sessionStorage.getItem("offerSelectedTab") || "query");
  const [policiesSelectedTab, setPoliciesSelectedTab] = useState(sessionStorage.getItem("policiesSelectedTab") || getKeyByValue(policiesConfig, "Активни"));
  const [policy, setPolicy] = useState({});
  const searchQuery = _get(qs.parse(props?.location?.search, { ignoreQueryPrefix: true }), "query", "");
  const tabsState = { selectedTab, offerSelectedTab, policiesSelectedTab, installmentsSelectedTab };

  const handleOfferChange = (e, selected) => {
    setOfferSelectedTab(selected);
    sessionStorage.setItem("offerSelectedTab", selected);
  };
  const handleInstallmentsChange = (e, selected) => {
    setInstallmentsSelectedTab(selected);
    sessionStorage.setItem("installmentsSelectedTab", selected);
  };
  const handlePoliciesChange = (e, selected) => {
    setPoliciesSelectedTab(selected);
    sessionStorage.setItem("policiesSelectedTab", selected);
  };
  const handleAction = (policy, option, refreshDataMethod) => {
    setPolicy(policy);
    if (option.hasModal) {
      props.open(`${option.id}Modal`);
      props.setRefreshMethod(refreshDataMethod);
      props.closeAllExceptOne(`${option.id}Modal`);
    } else if (!option.hasModal && option.id === "editPolicy") {
      //todo да се провери дали policy._id го има при installments
      props.navigate(`${routesConfig.editPolicy.route}/${policy._id}`);
    } else if (!option.hasModal && option.id === "sendDocuments") {
      if (selectedTab === "installments") {
        if (policy.deliveryName) {
          return props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/${policy.paymentId}/${policy.productId}/${policy.email}/${policy.deliveryName}`);
        } else {
          return props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/${policy.paymentId}/${policy.productId}/${policy.email}/${policy.name}`);
        }
      } else {
        props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/1/${policy.productId}/${policy.email}/${policy.name}`);
      }
    } else if (!option.hasModal && option.id === "sendDocumentsToInsurer") {
      props.navigate(`${routesConfig.sendDocumentsToInsurer.route}/${policy._id}/${policy.productId}/${policy.name}`);
    } else if (!option.hasModal && option.id === "sendPolicy") {
      props.navigate(`${routesConfig.sendPolicy.route}/${policy._id}/${policy.productId}/${policy.email}/${policy.name}`);
    }
  };
  const innerTabsPolicies = (
    <InnerTabs
      selected={policiesSelectedTab}
      handleChange={handlePoliciesChange}
      options={[
        { label: "Активни", value: "active" },
        { label: "Прекратени", value: "cancelled" },
        { label: "Всички", value: "all" }
      ]}
    />
  );
  const innerTabsOffers = (
    <InnerTabs
      selected={offerSelectedTab}
      handleChange={handleOfferChange}
      options={[
        { label: "Обработват се", value: "query" },
        { label: "Подписани от клиента", value: "signedByCustomer" },
        { label: "Изпратени на Застрахователя", value: "sentToInsurer" },
        { label: "Одобрени", value: "confirmed" },
        { label: "Отказани", value: "rejected" }
      ]}
    />
  );
  const innerTabsInstallments = (
    <InnerTabs
      selected={installmentsSelectedTab}
      handleChange={handleInstallmentsChange}
      options={[
        { label: "Предстоящи", value: getKeyByValue(variantLookup, "Предстоящи") },
        {
          label: "Просрочени",
          value: getKeyByValue(variantLookup, "Просрочени")
        },
        { label: "Информирани", value: getKeyByValue(variantLookup, "Информирани") },
        { label: "Прекратени", value: getKeyByValue(variantLookup, "Прекратени") },
        { label: "Платени", value: getKeyByValue(variantLookup, "Платени") },
        { label: "Всички", value: "all" }
      ]}
    />
  );
  useEffect(() => {}, [props.dataStatuses]);
  return (
    <PageLayout title="Здравни застраховки" hasGrayBg {...props}>
      <ActionsProvider>
        {Object.keys(modals).map(k => {
          let modal = modals[k];
          return createElement(modal, {
            key: k,
            [`is${capitalizeFirstLetter(k)}Open`]: _get(_find(props.dataStatuses, { id: k }), "opened"),
            toggle: props.toggle,
            close: props.close,
            refreshTable: props?.refreshData,
            policy: ["refundModal", "incorrectPayersModal"].includes(k) ? policy : undefined,
            //todo тук могат да отпаднат policyDBId и paymentId като се преработи PayInstallmentModal модала
            objWithEgnAndRequestId: !["refundModal", "incorrectPayersModal"].includes(k)
              ? { ...policy, policyDBId: _get(policy, "_id"), paymentId: selectedTab === "installments" ? _get(policy, "paymentId")?.toString() : "1" }
              : undefined,
            variant: ["changeStatusPolicyModal", "issuePolicyWizardModal", "sendForEvrotrustSigningModal", "changeStatusModal", "refundModal"].includes(k)
              ? selectedTab
              : undefined,
            subVariant: ["changeStatusPolicyModal", "issuePolicyWizardModal", "sendForEvrotrustSigningModal", "changeStatusModal", "refundModal"].includes(k)
              ? tabsState[subVariantsConfig[selectedTab]]
              : undefined
          });
        })}

        {selectedTab === "queries" && (
          <>
            {offerSelectedTab === "query" && (
              <PoliciesTableNew
                {...props}
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  LFE: "Застраховка живот"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["pending"]}
                offerDefaultFilter={[]}
                offerstatusDefaultFilter={["statusquerynone"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={["productId", "status", "offer", "offerStatus", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
            {offerSelectedTab === "signedByCustomer" && (
              <PoliciesTableNew
                {...props}
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  LFE: "Застраховка живот"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["pending"]}
                offerDefaultFilter={[]}
                offerstatusDefaultFilter={["statussignedbycustomer"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={["productId", "status", "offer", "offerStatus", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
            {offerSelectedTab === "sentToInsurer" && (
              <PoliciesTableNew
                {...props}
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  LFE: "Застраховка живот"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["pending"]}
                offerDefaultFilter={[]}
                offerstatusDefaultFilter={["statussenttoinsurer"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={["productId", "status", "offer", "offerStatus", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
            {offerSelectedTab === "confirmed" && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  LFE: "Застраховка живот"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["pending"]}
                offerDefaultFilter={[]}
                offerstatusDefaultFilter={["offerstatusconfirmed"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={["productId", "status", "offer", "offerStatus", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
            {offerSelectedTab === "rejected" && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  LFE: "Застраховка живот"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusDefaultFilter={["cancelled"]}
                offerDefaultFilter={[]}
                offerstatusDefaultFilter={["offerstatusrejected"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={["productId", "status", "offer", "offerStatus", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
            {/*{offerSelectedTab === "issued" && (*/}
            {/*  <PoliciesTableNew*/}
            {/*    title=""*/}
            {/*    variant={selectedTab}*/}
            {/*    subVariant={offerSelectedTab}*/}
            {/*    productLookup={{*/}
            {/*      LFE: "Застраховка живот"*/}
            {/*    }}*/}
            {/*    primacy="policyPrimacy"*/}
            {/*    productDefaultFilter={["LFE"]}*/}
            {/*    statusDefaultFilter={["active"]}*/}
            {/*    offerDefaultFilter={[]}*/}
            {/*    offerstatusDefaultFilter={[]}*/}
            {/*    insurerIdLookup={insurerIdLookup}*/}
            {/*    insurerIdDefaultFilter={[]}*/}
            {/*    handleAction={handleAction}*/}
            {/*    searchQuery={searchQuery}*/}
            {/*    fromDays="-330"*/}
            {/*    toDays="999"*/}
            {/*    listOfProps={["productId", "status", "offer", "offerStatus", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}*/}
            {/*    setSelectedTab={setSelectedTab}*/}
            {/*    innerTabs={innerTabsOffers}*/}
            {/*  />*/}
            {/*)}*/}
          </>
        )}

        {selectedTab === "policies" && (
          <>
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Активни") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  LFE: "Застраховка живот"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusDefaultFilter={["active"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Прекратени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  LFE: "Застраховка живот"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusDefaultFilter={["cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Всички") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                primacy="policyPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusDefaultFilter={[]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
          </>
        )}
        {selectedTab === "installments" && (
          <>
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Предстоящи") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["process"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Информирани") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["informed"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Просрочени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["overdue"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-365"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Прекратени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["rejected", "partiallyPaid", "cancelled"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Платени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusDefaultFilter={[]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["issued"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === "all" && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"]}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
          </>
        )}
      </ActionsProvider>
    </PageLayout>
  );
};
export default withMultipleModals(withParams(LFE), [
  { id: "refundModal" },
  { id: "cancelPolicyModal" },
  { id: "changeStatusModal" },
  { id: "checkGOStatusModal" },
  { id: "changeStatusPolicyModal" },
  { id: "createGOPolicyModal" },
  { id: "createPolicyModal" },
  { id: "incorrectPayersModal" },
  { id: "riskyUserProfileModal" },
  { id: "payInstallmentModal" },
  { id: "updateDateOfDuePaymentModal" },
  { id: "issuePolicyWizardModal" },
  { id: "sendForEvrotrustSigningModal" }
]);
